<template>
  <div class="document-frame-container">
    <svg
      viewBox="0 0 3508 2481"
      xmlns="http://www.w3.org/2000/svg"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <path
        d="M3507.87,-0L-0,-0L-0,2480.32L3507.87,2480.32L3507.87,-0ZM3227.83,466.814C3227.83,383.935 3160.55,316.648 3077.67,316.648L430.208,316.648C347.329,316.648 280.041,383.935 280.041,466.814L280.041,2013.5C280.041,2096.38 347.329,2163.67 430.208,2163.67L3077.67,2163.67C3160.55,2163.67 3227.83,2096.38 3227.83,2013.5L3227.83,466.814Z"
        style="fill: #121212; fill-opacity: 0.7"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DocumentFrameInWidth',
};
</script>

<style lang="scss" scoped>
.document-frame-container {
  width: fit-content;
  height: 100% !important;

  svg {
    text-align: center;
    height: 100%;
  }
}
</style>
