<template>
  <div class="circle-frame-container">
    <svg
      viewBox="0 0 3508 2481"
      xmlns="http://www.w3.org/2000/svg"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <path
        d="M3507.87,-0L-0,-0L-0,2480.32L3507.87,2480.32L3507.87,-0ZM1753.94,266.723C2291.19,266.723 2727.37,702.904 2727.37,1240.16C2727.37,1777.41 2291.19,2213.59 1753.94,2213.59C1216.68,2213.59 780.502,1777.41 780.502,1240.16C780.502,702.904 1216.68,266.723 1753.94,266.723Z"
        style="fill: #121212; fill-opacity: 0.7"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LivenessCheckFrame',
};
</script>

<style lang="scss" scoped>
.circle-frame-container {
  width: fit-content;
  height: 100% !important;

  svg {
    text-align: center;
    height: 100%;
  }
}
</style>
