<template>
  <div class="document-frame-container">
    <svg
      viewBox="0 0 2481 3508"
      xmlns="http://www.w3.org/2000/svg"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <path
        d="M2480.32,-0L0,-0L0,3507.87L2480.32,3507.87L2480.32,-0ZM2282.83,1206.86C2282.83,1148.22 2235.23,1100.62 2176.6,1100.62L303.719,1100.62C245.088,1100.62 197.487,1148.22 197.487,1206.86L197.487,2301.02C197.487,2359.65 245.088,2407.25 303.719,2407.25L2176.6,2407.25C2235.23,2407.25 2282.83,2359.65 2282.83,2301.02L2282.83,1206.86Z"
        style="fill: rgb(35, 31, 32); fill-opacity: 0.7"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DocumentFrameInHeight',
};
</script>

<style lang="scss" scoped>
.document-frame-container {
  width: 100% !important;
  height: fit-content;

  svg {
    text-align: center;
    width: 100%;
  }
}
</style>
