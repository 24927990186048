<template>
  <div>
    <template v-if="photoType === 'liveness'">
      <div v-if="!isStreamActive">
        <span class="title font-weight-bold">{{ $t('general.load_video') }}</span>
      </div>

      <div v-if="photoType === 'liveness'" class="web-camera-container">
        <video
          ref="video"
          :class="facingMode === 'user' ? 'web-camera-container__video-scaled' : ''"
          :width="width"
          :height="height"
          :src="source"
          :autoplay="autoplay"
          :playsinline="playsinline"
          loop
          muted
        />

        <div v-if="isStreamActive" class="web-camera-container__frame">
          <LivenessCheckFrame v-if="photoType === 'liveness'" />
          <slot name="moving-point"></slot>
        </div>
      </div>
    </template>

    <template v-else>
      <v-dialog v-model="dialog" max-width="600px" dark>
        <template v-slot:activator="{ on, attrs }">
          <div class="web-camera__activator" v-bind="attrs" v-on="on">
            <slot name="activator"></slot>
          </div>
        </template>

        <v-card class="d-flex flex-column">
          <v-toolbar dense>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="web-camera-container web-camera-container__document">
              <video
                ref="video"
                :class="facingMode === 'user' ? 'web-camera-container__video-scaled' : ''"
                :width="isMobileDevice ? 'auto' : width"
                :height="height"
                :src="source"
                :autoplay="autoplay"
                :playsinline="playsinline"
                loop
                muted
              />

              <div
                v-if="isStreamActive && photoType === 'document'"
                class="web-camera-container__frame"
                :class="`web-camera-container__${isMobileDevice ? 'vertical' : 'horizontal'}`"
              >
                <DocumentFrameInHeight v-if="isMobileDevice" />
                <DocumentFrameInWidth v-else />
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col cols="3"></v-col>

              <v-col cols="6" class="text-center">
                <v-btn icon x-large :loading="shooting" @click="uploadImage">
                  <v-icon x-large>mdi-camera</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="3" class="text-center">
                <v-btn icon @click="changeCamera">
                  <v-icon>mdi-rotate-3d-variant</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
/**
 * Base from package vue-web-cam from VinceG
 * @author VinceG
 * @github https://github.com/VinceG/vue-web-cam
 */

import { convertDataUriToBlob } from '@/common/reusable/imageFunctions';
import LivenessCheckFrame from '@/packages/web-camera/components/LivenessCheckFrame';
import DocumentFrameInHeight from '@/packages/web-camera/components/DocumentFrameInHeight';
import DocumentFrameInWidth from '@/packages/web-camera/components/DocumentFrameInWidth';

export default {
  name: 'WebCamera',
  components: {
    DocumentFrameInWidth,
    DocumentFrameInHeight,
    LivenessCheckFrame,
  },
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    photoType: {
      type: String,
      default: 'photo',
      validator: (value) => {
        const availableTypes = ['photo', 'document', 'liveness'];

        return availableTypes.includes(value);
      },
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: 'image/jpeg',
    },
    selectFirstDevice: {
      type: Boolean,
      default: false,
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    defaultFacingMode: {
      type: String,
      default: 'user',
      validator: (value) => {
        const availableChoices = ['user', 'environment'];

        return availableChoices.includes(value);
      },
    },
  },
  data() {
    return {
      dialog: false,
      source: null,
      camerasListEmitted: false,
      cameras: [],
      streaming: false,
      shooting: false,
      facingMode: 'environment',
    };
  },
  computed: {
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    isStreamActive() {
      return this.streaming;
    },
  },
  watch: {
    dialog: {
      handler: function (isWebCameraDialogOpen) {
        if (isWebCameraDialogOpen) {
          this.start();
        } else {
          this.stop();
        }
      },
    },
  },
  mounted() {
    this.setupMedia();
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    legacyGetUserMediaSupport() {
      return (constraints) => {
        let getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    },
    setupMedia() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = null;
      }

      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport();
      }

      this.facingMode = this.defaultFacingMode;
      this.testMediaAccess();
    },
    loadCameras() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          for (let i = 0; i !== deviceInfos.length; ++i) {
            let deviceInfo = deviceInfos[i];
            if ('videoinput' === deviceInfo.kind) {
              this.cameras.push(deviceInfo);
            }
          }
        })
        .then(() => {
          if (!this.camerasListEmitted) {
            this.$emit('cameras', this.cameras);
            this.camerasListEmitted = true;
          }
        })
        .catch((error) => this.$emit('notsupported', error));
    },
    changeCamera() {
      this.stop();

      if (this.cameras?.length > 1) {
        this.$emit('camera-change');
        this.facingMode = 'user' === this.facingMode ? 'environment' : 'user';
      }

      this.loadCamera();
    },
    loadSrcStream(stream) {
      if ('srcObject' in this.$refs.video) {
        this.$refs.video.srcObject = stream;
      } else {
        this.source = window.HTMLMediaElement.srcObject(stream);
      }

      this.$refs.video.onloadedmetadata = () => {
        this.streaming = true;
        this.$emit('video-live', stream);
      };

      this.$emit('started', stream);
    },
    stopStreamedVideo(videoElem) {
      let stream = videoElem.srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
        this.$emit('stopped', stream);
        this.$refs.video.srcObject = null;
        this.source = null;
      });
    },
    start() {
      if (this.cameras.length > 0) {
        this.loadCamera();
      }
    },
    stop() {
      if (this.$refs.video !== null && this.$refs.video?.srcObject) {
        this.stopStreamedVideo(this.$refs.video);
      }
    },
    testMediaAccess() {
      let constraints = {
        audio: false,
        video: {
          facingMode: this.facingMode,
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          let tracks = stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.loadCameras();
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },
    loadCamera() {
      let constraints = {
        audio: false,
        video: {
          facingMode: this.facingMode,
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.loadSrcStream(stream);
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },
    getImage() {
      const video = this.$refs.video;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      return canvas;
    },
    capture() {
      return this.getImage().toDataURL(this.screenshotFormat);
    },
    async uploadImage() {
      this.shooting = true;

      const image = convertDataUriToBlob({ dataURI: this.capture() });

      return this.$emit('upload-image', image);
    },
  },
};
</script>

<style lang="scss" scoped>
.web-camera__activator {
  text-align: center;
}

.web-camera-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  video {
    max-height: 65vh;
  }

  &__video-scaled {
    transform: scale(-1, 1);
  }

  &__document {
    display: flex;
    justify-content: center;
  }

  &__vertical {
    display: flex;
    align-items: center;
  }

  &__horizontal {
    display: flex;
    justify-content: center;
  }

  &__frame {
    position: absolute;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
