<template>
  <div>
    <v-card v-if="isSent" class="mx-5 mx-md-12 info-box" elevation="0">
      <v-alert border="left" color="#9D6F42" outlined class="px-10">
        <v-col cols="12" class="mt-5 pa-0 pb-5">
          {{ $t('general.sent_to_mobile') }}
        </v-col>
      </v-alert>
    </v-card>

    <v-card v-else-if="loadChanges" class="mx-5 mx-md-12 info-box" elevation="0">
      <v-alert border="left" color="#9D6F42" outlined class="px-10">
        <v-toolbar
          dense
          flat
          style="background-color: transparent; position: absolute; top: 0; right: 0"
        >
          <v-spacer></v-spacer>
          <v-btn icon @click="loadChanges = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-col cols="12" class="mt-5 pa-0 pb-5">{{ sentText }}</v-col>

        <v-col class="pa-0 pb-5">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="8" class="pr-3">
              <SPhoneNumberInput
                v-model="phoneNumber"
                :disabled="isDisabledChangePhoneNumber"
                autofocus
                required
                @on-validity-change="phoneNumberValid = $event"
              />
            </v-col>

            <v-col cols="4">
              <v-btn
                color="primary"
                :loading="isSubmitted"
                :disabled="phoneNumber === '' || !phoneNumberValid"
                block
                x-large
                @click="sendToPhone()"
              >
                {{ $t('general.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-alert>
    </v-card>

    <v-card v-else class="mx-5 mx-md-12 info-box" elevation="0">
      <v-alert type="warning" icon="mdi-information-outline" border="left" color="#9D6F42" outlined>
        <v-col cols="12" class="pa-0 pb-5">{{ infoText }}</v-col>

        <v-col cols="12" class="pa-0">
          <v-btn color="primary" outlined @click="loadChanges = true">
            {{ $t('general.continue_with_mobile') }}
          </v-btn>
        </v-col>
      </v-alert>
    </v-card>

    <AlertMessage v-model="alert" :message="messageData.message" :type="messageData.type" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/plugins/i18n';
import { apiTokenBasedRequest } from '@/services/ApiService';
import AlertMessage from '@verification/components/AlertMessage';
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';

export default {
  name: 'SendToPhoneBox',
  components: {
    SPhoneNumberInput,
    AlertMessage,
  },
  data() {
    return {
      alert: false,
      messageData: {
        message: i18n.t('general.error'),
        type: 'error',
      },
      phoneNumber: '',
      phoneNumberValid: false,
      submitted: false,
      sent: false,
    };
  },
  props: {
    value: Boolean,
    infoText: {
      type: String,
      required: true,
    },
    sentText: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isDisabledChangePhoneNumber: 'auths/isDisabledChangePhoneNumber',
      prefilledPhoneNumber: 'auths/prefilledPhoneNumber',
    }),
    loadChanges: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isSubmitted() {
      return this.submitted;
    },
    isSent() {
      return this.sent;
    },
  },
  mounted() {
    this.phoneNumber = this.prefilledPhoneNumber;
  },
  methods: {
    ...mapActions({
      setModeDispatchToMobile: 'auths/setModeDispatchToMobile',
      setModeEditing: 'auths/setModeEditing',
    }),
    sendToPhone() {
      this.submitted = true;

      apiTokenBasedRequest('POST', '/api/v1/auth/mobile', this.$route.params.authToken, {
        'phone-number': this.phoneNumber,
        url: `${window.location.href}`,
      })
        .then(() => {
          this.setModeEditing(false);
          this.setModeDispatchToMobile(true);
          this.$emit('user-change-select');
          this.messageData = {
            message: this.$t('general.sent'),
            type: 'success',
          };
          this.sent = true;
          this.alert = true;
          this.submitted = false;
          this.loadChanges = false;
        })
        .catch(() => {
          this.alert = true;
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box {
  .v-alert {
    background-color: #fffad1 !important;
    border: unset !important;
    font-weight: bolder;
    text-align: left;
  }
}
</style>
